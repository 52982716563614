<template>
    <div class="py-24 dark:bg-gray-900 bg-white">
        <div class="lg:container mx-auto dark:text-white text-black">

            <div class="sm:mx-5 lg:mx-5">
                <div>
                    <a class="py-3 px-6 bg-blue-300  lg:m-0 hover:bg-teal-300 font-medium cursor-pointer rounded transition duration-500 text-black"
                       @click.prevent="$router.back()">Назад</a>

                    <div class="flex lg:flex-row sm:flex-col mt-5">
                        <div class="lg:w-1/3 sm:w-full lg:items-center flex justify-center">
                            <div><img class="w-full" :src="item.image" alt="soft-image"></div>
                        </div>
                        <div class="lg:w-2/3 sm:w-full flex flex-col items-start  ">
                            <h1 class="sm:py-5 s lg:p-0 lg:text-4xl sm:text-2xl dark:text-white text-gray-700">
                                {{ item.title }}</h1>
                            <div class=" dark:text-white text-gray-700 text-detail my-5 " v-html="item.detail"></div>
                            <span class="text-3xl font-bold text-blue-300 mt-5">{{ item.price }}</span><span
                            v-if="item.price">*с учетом НДС</span>
                            <a class="cursor-pointer  text-blue-500 mb-5" :href="item.price_list"><i
                                class="fas fa-file-download"></i> <span class="px-2">Скачать прайс-лист</span>
                            </a>
                        </div>
                    </div>

                </div>

                <div class="">
                    <div class="grid lg:grid-cols-3 sm:grid-cols-2 w-full mt-10 gap-8"
                         v-if="item.code === 'e-declarant'">

                        <div class="flex flex-col justify-between items-center" v-for="(icon, index) in icons" :key='index'>
                            <router-link :to="icon.path" tag="a" class=""><img class="icon" :src="icon.img" alt="">
                            </router-link>
                            <p class="text-center mt-1">{{ icon.title }}</p>
                        </div>

                    </div>

                    <div class="grid lg:grid-cols-3  sm:grid-cols-2 w-full mt-10 ml-3 gap-8"
                         v-if="item.code !== 'e-declarant'">

                        <div class="flex flex-col justify-between items-center" v-for="(icon, index) in filtered"
                             :key='index'>
                            <router-link :to="icon.path" tag="a" class=""><img img class="icon" :src="icon.img" alt="">
                            </router-link>
                            <p class="text-center mt-1">{{ icon.title }}</p>
                        </div>

                    </div>
                </div>
            </div>



        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "SoftwareProductsDetail",
    data() {
        return {
            item: {},
            icons: [
                {
                    img: require('@/assets/img/pages/software/software_updates(1).svg'),
                    title: 'Обновления ПО',
                    path: '/software/updates',
                    code: ''
                },
                {
                    img: require("@/assets/img/pages/software/software_support(2).svg"),
                    title: 'Техническая поддержка',
                    path: '/support',
                    code: ''
                },
                {
                    img: require("@/assets/img/pages/software/software_form(3).svg"),
                    title: 'Приобрести продукт',
                    path: '/',
                    code: ''
                },
                {
                    img: require("@/assets/img/pages/software/software_manuals(4).svg"),
                    title: 'Инструкции и файлы',
                    path: `/software/manual/${this.$route.params.code}`,
                    code: ''
                },
                {
                    img: require("@/assets/img/pages/software/software_sertificate(5).svg"),
                    title: 'Свидетельство о регистрации',
                    path: '/',
                    code: ''
                },
                {
                    img: require("@/assets/img/pages/software/software_recommendations(6).svg"),
                    title: 'Рекомендации',
                    path: '/',
                    code: ''
                },
                {
                    img: require("@/assets/img/pages/software/software_instruction-avest(7).svg"),
                    title: 'Инструкция по устранению ошибок',
                    path: '/software/updates',
                    code: ''
                },
                {
                    img: require("@/assets/img/pages/software/software_updates(1).svg"),
                    title: 'Обновления клиентской части "е-Декларант"',
                    path: `/software/updates/client/${this.$route.params.code}`,
                    code: ''
                },
                {
                    img: require("@/assets/img/pages/software/software_updates(1).svg"),
                    title: 'ПП е-Клиент и ЭЦП',
                    path: '/software/updates',
                    code: ''
                },
            ],
        }
    },
    created() {
        this.fetch()
    },
    computed: {
        filtered() {
            let filteredIcons = this.icons.slice(0, -3);
            return filteredIcons
        }

    },
    methods: {
        async fetch() {
            axios.get(`https://declarant.by/rest/software/${this.$route.params.code}`)
                .then((response) => this.item = response.data.data)
        }
    },
}
</script>

<style scoped>

.dark .text-detail >>> * {
    color: rgb(212, 212, 212) !important;
}
.text-detail >>> span {
    background-color: inherit !important;
}
.dark .icon {
    filter: invert(1) ;
}

.block-hover {
    position: relative;
}

.prompt {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.7);
    padding: 20px;
    display: none;
}

.block-hover:hover .prompt {
    display: block;
}
</style>